import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import {
  DisplayGrid,
  GridType,
  GridsterConfig,
  GridsterModule,
} from 'angular-gridster2';
import { DynamicModule } from 'ng-dynamic-component';
import { IDashboardWidgetDetailDto } from '../models/widget.models';
import { WidgetComponent } from '../widget/widget.component';

@Component({
  standalone: true,
  selector: 'bo-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [
    CommonModule,
    GridsterModule,
    TranslocoModule,
    DynamicModule,
    WidgetComponent,
  ],
})
export class DashboardComponent {
  @HostBinding('class') class = 'bo-dashboard';

  private _options: GridsterConfig = {
    gridType: GridType.Fit,
    displayGrid: DisplayGrid.OnDragAndResize,
    // minCols: 50,
    // minRows: 50,
    pushItems: true,
    draggable: {
      enabled: false,
    },
    resizable: {
      enabled: false,
    },
    // scale: 1,
    // margin: 1,
  };

  @Input()
  public set options(value: GridsterConfig) {
    if (value) {
      Object.assign(this._options, value);
      if (this._options.api && this._options.api.optionsChanged) {
        this._options.api.optionsChanged();
      }
    }
  }

  public get options(): GridsterConfig {
    return this._options;
  }

  @Input()
  widgets: Array<IDashboardWidgetDetailDto>;

  @Input()
  headerTemplate: TemplateRef<any>;

  public addWidget() {}

  castToAnyType(item: any): any {
    return <any>item.type;
  }
}
