import { Injectable, Injector } from '@angular/core';
import { TenantService } from '@clean-code/shared/util/util-tenant';
import { IWidgetTypeProvider } from '../models/widget-type.models';
import { IWidgetTypeConfigComponent } from '../models/widget-types-config.models';
import { IWidgetTypeContentComponent } from '../models/widget-types-content.models';

@Injectable({
  providedIn: 'root',
})
export class WidgetTypeProvider {
  private widgetTypes: IWidgetTypeProvider[] = [];

  constructor(private tenantService: TenantService) {}

  public async add(
    widgetTypeName: string,
    configClass: { new (injector?: Injector): IWidgetTypeConfigComponent },
    contentClass: { new (injector?: Injector): IWidgetTypeContentComponent },
    tenant: string[],
    minItemRows = 7,
    minItemCols = 5,
    permissions = new Array<string>()
  ) {
    tenant.forEach(async (tenant) => {
      await this.tenantService.validateTenant(tenant);
    });
    if (!this.getProvider(widgetTypeName)) {
      this.widgetTypes.push({
        widgetTypeName,
        configCmp: configClass,
        contentCmp: contentClass,
        minItemRows,
        minItemCols,
        tenant: tenant,
        permissions,
      });
    }
  }

  public getProvider(widgetTypeName: string): IWidgetTypeProvider {
    return this.widgetTypes.find((t) => widgetTypeName === t.widgetTypeName);
  }

  public getConfigComponent(widgetTypeName: string) {
    const provider = this.widgetTypes.find(
      (t) => widgetTypeName === t.widgetTypeName
    );
    if (provider) {
      return provider.configCmp;
    }
    return null;
  }

  public getContentComponent(widgetTypeName: string) {
    const provider = this.widgetTypes.find(
      (t) => widgetTypeName === t.widgetTypeName
    );
    if (provider) {
      return provider.contentCmp;
    }
    return null;
  }

  public getAll(): IWidgetTypeProvider[] {
    return this.widgetTypes;
  }
}
